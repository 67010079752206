import { useContext } from "react";
import { Context } from "../../context/Context";

import EventCard from "./EventCard";

const EventsSections = ({ programRef }) => {

  const { programEvents } = useContext(Context);

  const displayEventCards = programEvents.map((event, index) => {
    return (
      <EventCard key={index} event={event} />
    )
  });

  return (
    <div ref={programRef}>
      <div  className="bg-[#171717] pb-10">
          <h2 className="text-white font-bold text-5xl sm:text-6xl md:text-7xl lg:text-7xl xl:text-7xl text-center pt-20 pb-16 align-baseline flex items-center justify-center">
            <i className="fi fi-sr-calendar-days me-6 mt-1 sm:max-5xl:mt-0 text-[2.7rem] sm:max-5xl:text-6xl"></i> PROGRAM
          </h2>

          <div id="event-cards-container" className="grid grid-cols-1 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-4 justify-items-center">
            {displayEventCards}
          </div>
          <div ref={programRef}></div>
      </div>
    </div>
  )
}

export default EventsSections