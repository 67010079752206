
// Import components
import VideosGrid from "./VideosGrid";

const GallerySection = ({ galleryRef, galleryOption, setGalleryOption }) => {

  const handleSelectedOption = (option) => {
    setGalleryOption(option);
  };

  return (
    <div ref={galleryRef} className="bg-[#171717] pb-10">
        <h2 className="text-white font-bold text-5xl sm:max-5xl:text-7xl text-center pt-20 pb-16 align-baseline flex items-center justify-center">
          <i className="fi fi-br-layout-fluid me-6 mt-1 sm:max-5xl:mt-2 text-[2.5rem] sm:max-5xl:text-6xl"></i> GALLERY
        </h2>

        <div className="grid grid-cols-2 text-white text-lg sm:text-xl md:text-2xl lg:2xl:text-3xl font-semibold">
          <button onClick={() => handleSelectedOption("after_movies")} className={`mx-5 flex items-center justify-center justify-items-center gap-3 border-b-2 ${galleryOption === "after_movies" ? "border-b-white" : "border-b-[#171717] hover:border-b-white"}`}>
            <i className="fi fi-rr-film no-underline text-lg md:text-xl lg:text-2xl mt-1 lg:mt-2"></i>
            <span className="">After Movies</span>
          </button>
          <button onClick={() => handleSelectedOption("vlogs")} className={`mx-5 flex items-center justify-center justify-items-center gap-3 border-b-2 ${galleryOption === "vlogs" ? "border-b-white" : "border-b-[#171717] hover:border-b-white"}`}>
            <i className="fi fi-sr-video-camera-alt text-lg md:text-xl lg:text-2xl mt-1 lg:mt-2"></i>
            <span className="">Vlogs</span>
          </button>
        </div>
      <VideosGrid galleryOption={galleryOption} /> 
    </div>
  )
}

export default GallerySection