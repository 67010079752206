

const PlayerModal = ({ selectedVideo, closeVideo, isVisible }) => {
    return (
        <div>
            {/* Modal for Selected Video */}
            {selectedVideo && (
                <div className="fixed inset-0 z-[1000] flex items-center justify-center p-4 landscape:p-32">
                {/* Background Overlay */}
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                    onClick={closeVideo}
                ></div>
        
                {/* Modal Content */}
                <div
                    className={`no-scrollbar-content relative flex flex-col justify-center items-center align-middle ${selectedVideo.isShort ? "w-[20rem] h-[35.5rem] sm:w-[25.45rem] md:w-[25.25rem] sm:h-[45.25rem] md:h-[44.95rem]" : "w-full sm:max-w-7xl h-[38rem]"} transition-all duration-300 ease-in-out transform ${
                    isVisible ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
                    }`}
                >
                    <div className={`w-full ${selectedVideo.isShort ? "h-full landscape:max-h-svh landscape:max-w-screen-sm" : ""}  relative aspect-video flex items-center justify-center`}>
                        
                        {
                            selectedVideo.videoSrc ? (
                                <video
                                src={selectedVideo.videoSrc}
                                className="w-full h-full object-cover rounded-3xl shadow-xl"
                                title={selectedVideo.name}
                                controls={true} // Disables default controls
                                autoPlay
                                loop
                                onContextMenu={(e) => e.preventDefault()} // Prevents right-click
                                >

                                </video>
                            ) : (
                                <iframe
                                src={`https://www.youtube.com/embed/${selectedVideo.videoId}?si=HGHNFx9mNTSwlbni&autoplay=1&mute=0&loop=1&controls=1&color=white&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&playlist=${selectedVideo.videoId}`}
                                title={selectedVideo.name}
                                className="w-full h-full object-cover rounded-3xl shadow-xl"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                />
                            )
                        }
                        {/* Close Button */}
                        <button
                            className="absolute right-0 top-0 flex flex-col justify-items-end p-8 rounded-full transition"
                            onClick={closeVideo}
                        >
                            <i className="fi fi-br-cross text-lg sm:max-2xl:text-xl text-white hover:text-gray-200"></i>
                        </button>
                    </div>
                </div>
                </div>
            )}
        </div>
    )
}

export default PlayerModal