

const TimelineCard = ({ index, record, handleOpenModal }) => {

  const formatDate = (date) => {
    const year = date.getUTCFullYear();
    var month = date.toLocaleString('en-US', { month: 'long' });
    month = month.charAt(0).toUpperCase() + String(month).slice(1);
    const day = date.getUTCDate(); 

    return month + " " + day + "th, " + year
  };

  return (
    <div className="transition ease-out hover:scale-95 cursor-pointer snap-center" onClick={() => handleOpenModal(index)}>
      <div className="relative overflow-hidden bg-white rounded-lg text-white grid grid-cols-1 justify-center items-center w-60 xl:w-80">
          <div className="rounded-t-lg h-40 sm:max-5xl:h-64 flex">
              <img className="w-full h-full object-cover" src={record.src} alt={record.alt} />
          </div>
          <div className="bg-[#171717] rounded-b-lg p-5 h-44 flex flex-col justify-start">
              <h2 className="text-2xl sm:text-3xl font-bold mb-1">{record.name}</h2>
              <p className="text-md sm:text-lg text-wrap my-2 font-semibold">{record.location}</p>
              <span className="text-sm sm:text-md">{formatDate(record.date)}</span>
          </div>
      </div>
    </div>
  )
}

export default TimelineCard