import { useRef, useState } from 'react';

// Import React Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import components
import Navbar from './assets/components/Navbar';
import Landing from './pages/Landing';
import AboutUs from './pages/AboutUs';

// Import css
import './assets/css/App.css';
import './assets/css/index.css';

// Import Context
import { EventsProvider } from './assets/context/Context';

function App() {
  const [galleryOption, setGalleryOption] = useState("after_movies");

  const socialMedias = [
    {icon: 'fi-brands-instagram', link: 'https://www.instagram.com/friendsplusfamily_/', target: '_blank', rel: "noopener noreferrer"},
    {icon: 'fi-sr-envelope', link: 'mailto:info@friendsplusfamily.com', target: '_blank'},
    {icon: 'fi-brands-linkedin', link: 'https://www.linkedin.com/company/friendspluusfamily/', target: '_blank'},
  ]
  
  // references
  const homeRef = useRef(null);
  const programRef = useRef(null);
  const programRefEnd = useRef(null);
  const timelineRef = useRef(null);
  const galleryRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactRef = useRef(null);

  const handleScroll = (ref, name) => {
    switch (name) {
      case "AFTER MOVIES":
        setGalleryOption("after_movies");
      break;
      case "VLOGS":
          setGalleryOption("vlogs");
      break;
      default:
      break;
    }
    
    if (ref) {
      const offset = -100; // Adjust this value to match your desired offset
      const elementPosition = ref.current?.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition + offset;
    
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Router>
      <div>
        <Navbar
        homeRef={homeRef}
        programRef={programRef}
        programRefEnd={programRefEnd}
        timelineRef={timelineRef}
        galleryRef={galleryRef}
        aboutUsRef={aboutUsRef}
        contactRef={contactRef}
        handleScroll={handleScroll}
        />
        <Routes>
          <Route
          path='/'
          element={
            <EventsProvider>
              <Landing
                homeRef={homeRef}
                programRef={programRef}
                timelineRef={timelineRef}
                galleryRef={galleryRef}
                contactRef={contactRef}
                handleScroll={handleScroll}
                galleryOption={galleryOption}
                setGalleryOption={setGalleryOption}
                socialMedias={socialMedias}
              />
            </EventsProvider>
            }
            />
          <Route
          path='/about-us'
          element={
            <AboutUs 
            aboutUsRef={aboutUsRef}
            socialMedias={socialMedias} />
          }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
