import Logo from '../imgs/logos/logo_main.png';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = ({ handleScroll, homeRef, programRef, timelineRef, galleryRef, aboutUsRef, contactRef }) => {
    // Router
    const navigate = useNavigate();
    const currLocation = useLocation();

    const [activeIndex, setActiveIndex] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(null); // Track which dropdown is open
    const [scrollToSection, setScrollToSection] = useState([]);

    const links = [
        { name: 'HOME', link: '/', ref: homeRef },
        { name: 'PROGRAM', ref: programRef, section: '/'},
        { name: 'TIMELINE', ref: timelineRef, section: '/' },
        { name: 'GALLERY', dropdown: [
            { name: 'AFTER MOVIES', ref: galleryRef, section: '/', set: true },
            { name: 'VLOGS', ref: galleryRef, section: '/', set: true }
        ]},
        { name: 'ABOUT US', link: '/about-us', ref: aboutUsRef },
        { name: 'CONTACT', ref: contactRef, section: '/' },
    ];

    const handleMenuButton = () => {
        setOpenMenu(!openMenu);
    };

    const handleDropdown = (index) => {
        setDropdownOpen(dropdownOpen === index ? null : index); // Toggle the dropdown
    };

    const handleMobileDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const handleNavOptionClick = (index, subindex, isMobile) => {
        const activeOption = subindex != null ? links[index].dropdown[subindex] : links[index];
        if (isMobile && activeOption.name !== 'GALLERY') {
            setOpenMenu(false);
        }
        if (!isMobile && subindex != null) setDropdownOpen(null);
        if (isMobile && subindex != null) setOpenDropdownIndex(null);

        const navElement = activeOption.link || activeOption.section;
        if (navElement && currLocation.pathname !== navElement) {
            navigate(navElement);
        }
        setScrollToSection([activeOption.ref, activeOption.name]);
        setActiveIndex(index);
    };

    useEffect(() => {
        if (currLocation.pathname === '/' && scrollToSection) {
            handleScroll(scrollToSection[0], scrollToSection[1]);
            setScrollToSection(null); // Reset after scrolling
        }
        else if (currLocation.pathname === '/about-us' && scrollToSection) {
            handleScroll(scrollToSection[0], scrollToSection[1]);
            setScrollToSection(null); // Reset after scrolling
        }
    }, [currLocation, scrollToSection, handleScroll]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const index = links.findIndex(link =>
                            (link.dropdown && link.dropdown.some(item => item.ref && item.ref.current === entry.target)) ||
                            (link.ref && link.ref.current === entry.target));
                        if (index !== -1) setActiveIndex(index);
                    }
                });
            },
            { threshold: 0.30 }
        );

        // Observe each ref, including those in dropdowns
        links.forEach(link => {
            if (link.ref && link.ref.current) observer.observe(link.ref.current);
            if (link.dropdown) {
                link.dropdown.forEach(item => {
                    if (item.ref && item.ref.current) observer.observe(item.ref.current);
                });
            }
        });

        // Additional observer to set 'HOME' as active when at top of the page
        const handleScrollToTop = () => {
            if (window.scrollY === 0 && currLocation.pathname === '/') setActiveIndex(0);
        };
        window.addEventListener('scroll', handleScrollToTop);

        return () => {
            observer.disconnect();
            window.removeEventListener('scroll', handleScrollToTop);
        };
    }, [links]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(".group")) {
                setDropdownOpen(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const displayMobileLinks = links.map((link, index) => {
        if (link.dropdown) {
            return (
                <div key={index} className='my-2 cursor-pointer flex flex-col'>
                    <button
                    onClick={() => handleMobileDropdown(index)}
                        className={`nav-item flex justify-center items-center text-center font-bold gap-2 ${index === activeIndex ? 'text-white' : 'text-zinc-200'}`}
                    >
                        <span
                            className={`${index === activeIndex ? 'underline underline-offset-8' : 'hover:underline underline-offset-8'}`}>
                            {link.name}
                        </span>
                        <i className={`fi fi-ss-angle-${openDropdownIndex === index ? "up" : "down"} text-[0.40rem] mt-1 no-underline`}></i>
                    </button>
                    {openDropdownIndex === index && (
                        <div className='flex flex-col items-center'>
                            {link.dropdown.map((dropdownItem, subIndex) => (
                                <div
                                    key={subIndex}
                                    className="text-zinc-300 hover:text-white cursor-pointer mt-3"
                                    onClick={() => handleNavOptionClick(index, subIndex, true)}
                                >
                                    {dropdownItem.name}
                                </div>
                            ))}
                        </div>
                    )
                    }
                </div>
            )
        }
        else {
            return (
                <div key={index} className='my-2 cursor-pointer flex flex-col'>
                    {
                        link.target ? (
                            <a
                            href={link.link}
                            target={link.target}
                            rel={link.rel}
                            className="nav-item flex items-center font-bold text-zinc-200 hover:underline underline-offset-8">{link.name}</a>
                        ) : (
                            <span
                                className={`nav-item flex items-center font-bold ${index === activeIndex ? 'text-white underline underline-offset-8' : 'text-zinc-200 hover:underline underline-offset-8'}`}
                                onClick={() => handleNavOptionClick(index, null, true)}
                            >
                                {link.name}
                            </span>
                        )
                    }

                </div>
            )
        }
    });

    const displayLinks = links.map((link, index) => {
        if (link.dropdown) {
            return (
                <div className="relative group" key={index}>
                    <button
                        onClick={() => handleDropdown(index)}
                        className={`nav-item flex items-center font-bold gap-2 ${index === activeIndex ? 'text-white' : 'text-zinc-200'}`}
                    >
                        <span
                            className={`${index === activeIndex ? 'underline underline-offset-8' : 'hover:underline underline-offset-8'}`}>
                            {link.name}
                        </span>
                        <i className="fi fi-ss-angle-down text-[0.60rem] mt-1 no-underline"></i>
                    </button>
                    {/* Dropdown menu */}
                    <div
                        className={`absolute ${dropdownOpen === index ? 'block' : 'hidden'} bg-black text-white shadow-md p-2 mt-1 text-nowrap`}
                    >
                        {link.dropdown.map((option, i) => (
                            option.ref ? (
                                <button key={i} onClick={() => handleNavOptionClick(index, i)} className="block px-4 py-2 hover:bg-gray-800 cursor-pointer">{option.name}</button>
                            ) : (
                                <a key={i} href={option.link} className="block px-4 py-2 hover:bg-gray-800">{option.name}</a>
                            )
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                <div className=''>
                    {
                        link.target ? (
                            <a href={link.link} className='text-zinc-200 font-bold hover:text-white hover:underline underline-offset-8 transition duration-1000 ease-in-out cursor-pointer' target={link.target} rel={link.rel}>
                                {link.name}
                            </a>
                        ) : (
                            <span
                                key={index}
                                className={`text-zinc-200 font-bold hover:text-white hover:underline underline-offset-8 transition duration-1000 ease-in-out cursor-pointer ${
                                    index === activeIndex ? 'text-white underline' : ''
                                }`}
                                onClick={() => handleNavOptionClick(index)}
                            >
                                {link.name}
                            </span>
                        )
                    }
                </div>
            );
        }
    });

    return (
        <div className="bg-[#171717] sticky top-0 z-[1000]">
            <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-8">
                <div className="relative py-14 flex items-center justify-between h-16">
                    <a href='/' onClick={() => handleScroll(null)} className="cursor-pointer">
                        <img className='w-40 sm:w-64' src={Logo} alt="F+F" />
                    </a>
                    <div className="hidden lg:block sm:ml-6">
                        <div className="flex space-x-10">
                            {displayLinks}
                        </div>
                    </div>
                    <div className="inset-y-0 left-0 flex items-center lg:hidden">
                        <button onClick={handleMenuButton} id="mobile-menu-button" className="relative flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white w-10 h-10" aria-controls="mobile-menu" aria-expanded="false">
                            <i className="fi fi-rr-menu-burger mt-1"></i>
                        </button>
                    </div>
                </div>
            </div>
            {openMenu && (
                <div className="lg:hidden" id="mobile-menu">
                    <div className='flex flex-col items-center text-center py-5'>
                        {displayMobileLinks}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;
