import { useEffect, useState } from "react";

const TimelineModal = ({ handleCloseModal, selectedElement }) => {

    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {
        setIsVisible(true); // Animate open
        return () => setIsVisible(false); // Animate close on unmount
      }, []);

  // Fallback UI when data is null or loading
  if (!selectedElement) {
    return (
      <div className="fixed inset-0 z-[1000] flex items-center justify-center p-4 mt-14">
        {/* Background overlay */}
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={handleCloseModal}
        ></div>

        {/* Modal container */}
        <div className="relative flex flex-col bg-white rounded-lg shadow-xl w-full sm:max-w-md h-auto p-8 text-center">
          <h2 className="text-xl font-bold mb-4">Loading...</h2>
          <p>Please wait while we load the details.</p>
          <button
            className="mt-4 px-4 py-2 bg-gray-700 text-white rounded-lg"
            onClick={handleCloseModal}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center p-4">
        {/* Background overlay */}
        <div 
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true" 
            onClick={handleCloseModal}
        ></div>

        {/* Modal container */}
        <div className={`no-scrollbar-content relative flex flex-col bg-white rounded-xl shadow-xl w-full sm:max-w-5xl transition-all h-[35rem] sm:h-[40rem] duration-300 ease-in-out transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
            <div className="relative w-full h-full text-white">
                <img className="w-full h-full object-cover rounded-xl" src={selectedElement.src} alt={selectedElement.alt} />
                <div className="absolute inset-0 bg-black bg-opacity-50 rounded-xl"></div>
                <div className="absolute top-0 justify-center items-center h-full w-full p-6 sm:p-8 flex flex-col text-center">
                    <h1 className="text-6xl sm:text-9xl md:text-[10rem] font-bold">{selectedElement.name}</h1>
                    <p className="text-lg sm:text-2xl md:text-3xl mt-5 text-justify">
                        {selectedElement.description}
                    </p>
                </div>
                <button className="absolute right-0 top-0 flex flex-col justify-items-end p-8 rounded-full transition" onClick={handleCloseModal}>
                    <i className="fi fi-br-cross text-xl hover:text-gray-200"></i>
                </button>
            </div>
        </div>
    </div>
  )
}

export default TimelineModal