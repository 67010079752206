import React, { createContext } from "react";

// Import image covers
import ELEVATE from '../imgs/events/covers/1.jpeg'
import IXCHEL from '../imgs/events/covers/2.jpeg'
import VIU from '../imgs/events/covers/3.jpeg'
import ARAZURI from '../imgs/events/covers/4.jpeg'
import LA_BONITA from '../imgs/events/covers/5.jpeg'
import GALLO_NEGRO from '../imgs/events/covers/6.jpeg'
import SUPERB from '../imgs/events/covers/7.jpeg'

// Import events img flyers
import REPUBLIQ_FLYER from '../imgs/events/flyers/REPUBLIQ.jpg'
import MILLENIUM_FLYER from '../imgs/events/flyers/MILLENIUM01.jpg'

// Create the context
export const Context = createContext();

// Create the provider component
export const EventsProvider = ({ children }) => {
    const allEvents = [
      {
        name: 'ELEVATE',
        date: new Date('2023-07-21'),
        location: 'El Boquerón, San Salvador, El Salvador',
        videoId: "TTi6CeBQ31I",
        isShort: false,
        artists: '',
        src: ELEVATE,
        alt: 'elevate',
        description: 'On July 21, 2023, we launched Friends + Family with our inaugural event, Elevate, at El Boquerón in San Salvador, El Salvador. This intimate gathering of close friends and family set the foundation for our mission to create exceptional electronic music experiences, connect diverse cultures, and build a passionate global community.',
        flyerSrc: '',
        status: 'inactive',
      },
      {
        name: 'IXCHEL',
        date: new Date('2023-12-23'),
        location: 'Tamanique, La Libertad, El Salvador',
        videoId: "Fv3mB_6PxIA",
        isShort: false,
        artists: '',
        src: IXCHEL,
        alt: 'ixchel',
        description: 'Our brand made its initial debut with IXCHEL on December 23, 2023, at Ixchel Restaurant Hotel & Boutique in Tamanique, La Libertad. This large-scale event brought together a wider audience, celebrating music, culture, and connection in an unforgettable setting.',
        flyerSrc: '',
        status: 'inactive',
      },
      {
        name: 'VIU',
        date: new Date('2024-01-07'),
        location: 'San Salvador, El Salvador',
        videoId: "a-ObdNsipwY",
        isShort: false,
        artists: '',
        src: VIU,
        alt: 'viu',
        description: 'Held on January 7, 2024, at VIU Boutique Cabins in San Salvador, El Salvador, VIU marked the beginning of a pivotal year. By then, Friends + Family had established itself in the Salvadoran music scene, known for its quality events in unique venues.',
        flyerSrc: '',
        status: 'inactive',
      },
      { 
        name: 'ARAZURI',
        date: new Date('2024-04-13'),
        location: 'Arazuri, Navarre, Spain',
        videoId: "S9XMEihqG_g",
        isShort: false,
        placeholder: "",
        artists: 'Yubik',
        src: ARAZURI,
        alt: 'arazuri',
        description: 'ARAZURI, our first European event, took place on April 13, 2024, at the Castle of Arazuri in Navarre, Spain, and featured German artist Yubik. With over 700 attendees, this milestone event expanded our reach and showcased our commitment to uniting global music lovers.',
        flyerSrc: '',
        status: 'inactive',
      },
      { 
        name: 'LA BONITA',
        date: new Date('2024-07-28'),
        location: 'Tamanique, La Libertad, El Salvador',
        videoId: "dmvSOW4dCmk",
        isShort: false,
        placeholder: "",
        artists: 'Monkey Safari',
        src: LA_BONITA,
        alt: 'la_bonita',
        description: 'On July 28, 2024, La Bonita at La Bonita Beach Club in Tamanique marked our first Salvadoran event with an international artist. Featuring German duo Monkey Safari, this beachfront gathering solidified Friends + Family’s reputation for high-quality events in stunning locations.',
        flyerSrc: '',
        status: 'inactive',
      },
      {
        name: 'GALLO NEGRO',
        date: new Date('2024-08-15'),
        location: 'Guatemala City, Guatemala',
        videoId: "P30ZwXmK0V4",
        isShort: false,
        artists: 'Mooglie',
        src: GALLO_NEGRO,
        alt: 'gallo_negro',
        description: 'Gallo Negro took place on August 15, 2024, in Guatemala City, marking our first event in Guatemala and the start of the first Friends + Family tour. Featuring Parisian artist Mooglie, this event allowed us to connect with a new audience and establish our presence in the Guatemalan electronic music scene.',
        flyerSrc: '',
        status: 'inactive',
      },
      {
        name: 'SUPER B',
        date: new Date('2024-08-17'),
        location: 'San Salvador, El Salvador',
        videoId: "MLG9OBtsQM0",
        isShort: false,
        artists: 'Mooglie',
        src: SUPERB,
        alt: 'superb',
        description: 'On August 17, 2024, Superb at Club Superb in San Salvador concluded our inaugural Friends + Family tour. Featuring Mooglie once again, this event was a milestone, as we introduced electronic music to the venue for the first time, creating the perfect scenario to connect with local music lovers.',
        flyerSrc: '',
        status: 'inactive',
      },
      {
        name: 'REPUBLIQ',
        date: null,
        location: '',
        videoId: "",
        isShort: false,
        artists: '',
        src: '',
        alt: 'republiq',
        description: '',
        flyerSrc: REPUBLIQ_FLYER,
        link: 'https://lu.ma/i021vr8m',
        status: 'active',
      },
      {
        name: 'MILENIUM',
        date: null,
        location: '',
        videoId: "",
        isShort: false,
        artists: '',
        src: '',
        alt: 'milenium',
        description: '',
        flyerSrc: MILLENIUM_FLYER,
        link: 'https://tickets.barlleno.app/',
        status: 'active',
      }
    ];

    const events = allEvents.filter(event => event.status === 'inactive');
    const programEvents = allEvents.filter(event => event.status === 'active' || event.status === 'soon');

  return (
    <Context.Provider value={{ events, programEvents }}>
      {children}
    </Context.Provider>
  );
};
