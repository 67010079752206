
const HeroSection = ({ handleScroll, programRef }) => {
  return (
    <div className="relative overflow-hidden">
        <div className="w-full aspect-video">
            <iframe className="w-full h-full object-cover pointer-events-none"
            src="https://www.youtube.com/embed/VWCZzDrJ93A?si=HGHNFx9mNTSwlbni&autoplay=1&mute=1&loop=1&controls=0&color=white&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&playlist=VWCZzDrJ93A"
            title="Promotional Video"
            allow="autoplay; loop; encrypted-media"
            frameborder="0"
            allowFullScreen
            />
        </div>
        <div className="absolute bottom-8 sm:max-5xl:bottom-36 h-full w-full items-end justify-center flex">
          <button
          onClick={() => handleScroll(programRef)}
          className="bg-white text-[#171717] px-4 py-2 sm:max-5xl:px-5 sm:max-5xl:py-4 rounded-full hover:bg-[#171717] hover:text-white text-md sm:max-5xl:text-xl flex items-center font-semibold gap-2">
            Program
            <i className="fi fi-ss-angle-down text-xs sm:max-5xl:text-sm mt-2"></i>
          </button>
        </div>
    </div>
  )
}

export default HeroSection