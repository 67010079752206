import { useState, useEffect, useRef } from "react";

// Import images
import Slide1 from '../../imgs/carousel/1.jpg';
import Slide2 from '../../imgs/carousel/2.jpg';
import Slide3 from '../../imgs/carousel/3.jpg';
import Slide4 from '../../imgs/carousel/4.JPG';


const Carousel = () => {
    const [currSlide, setCurrSlide] = useState(1);
    const timeoutDuration = 5000;
    const [transitionClass, setTransitionClass] = useState('transition ease-in-out duration-400');
    const autoplayRef = useRef(null);

    var slides = [
        {src: Slide1, alt: 'slide-1'},
        {src: Slide2, alt: 'slide-2'},
        {src: Slide3, alt: 'slide-3'},
        {src: Slide4, alt: 'slide-4'},
    ];

    const adjustedSlides = [slides[slides.length - 1], ...slides, slides[0]];

    const moveHandler = (direction) => {
        setTransitionClass('transition ease-in-out duration-400');
        setCurrSlide((prev) => (direction ? prev - 1 : prev + 1));
    };

    const gotToSlide = (index) => {
        setTransitionClass('transition ease-in-out duration-400');
        setCurrSlide(index);
    };


    const handleTransitionEnd = () => {
        if (currSlide <= 0) {
            setTransitionClass('transition-none');
            setCurrSlide(adjustedSlides.length - 2);
        }
        else if (currSlide >= adjustedSlides.length - 1) {
            setTransitionClass('transition-none');
            setCurrSlide(1);
        }
    };

    useEffect(() => {
        autoplayRef.current = setInterval(() => {
            moveHandler(false);
        }, timeoutDuration);

        return () => clearInterval(autoplayRef.current);
    }, []);

  return (
    <div className="relative overflow-hidden rounded-2xl max-h-full max-w-full lg:h-[38rem]">
        <div
        className={`max-h-screen h-[40vh] md:h-[90vh] flex ${transitionClass}`}
        style={{ transform: `translateX(-${currSlide * 100}%)` }}
        onTransitionEnd={handleTransitionEnd}
        >
            {adjustedSlides.map((slide, index) => (
                <img key={index} src={slide.src} alt={slide.alt} className="w-full h-full object-cover" style={{flex: "1 0 100%"}} />
            ))}
        </div>
        <div className="absolute top-0 h-full w-full justify-between items-center flex text-white px-10 text-4xl invisible md:visible">
            <button className="" onClick={() => moveHandler(true)}>
                <i className="fi fi-sr-angle-left"></i>
            </button>
            <button className="" onClick={() => moveHandler(false)}>
                <i className="fi fi-sr-angle-right"></i>
            </button>
        </div>
        <div className="pagination absolute bottom-12 w-full flex gap-16 justify-center align-middle z-10">
            {slides.map((_, index) => (
                <button
                key={index}
                onClick={() => gotToSlide(index + 1)}
                className={`w-4 h-4 md:w-5 md:h-5 rounded-full bg-white ${currSlide === index + 1 ? 'opacity-90' : 'opacity-50'}`}
                ></button>
            ))}
        </div>
    </div>
  )
}

export default Carousel