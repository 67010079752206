import { useRef, useState, useEffect, useContext } from "react"
import { Context } from "../../context/Context"

// Import components
import TimelineCard from "./TimelineCard"
import TimelineModal from "./TimelineModal"


const TimelineSection = ({ timelineRef }) => {
  const { events } = useContext(Context);
  const scrollContainer = useRef(null);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);

  const handleScrollSide = (direction) => {
    const side = direction ? 1 : -1;

    scrollContainer.current.scrollBy({
      left: 1000 * side,
      behavior: 'smooth'
    });
  };

  const handleOpenModal = (index) => {
    setSelectedElement(events[index]);
    setIsModelOpen(true);
  }

  const handleCloseModal = () => {
    setIsModelOpen(false);
    setSelectedElement(null);
  }

  const displayTimelineCards = events.map((record, index) => {
    return (
      <TimelineCard key={index} index={index} record={record} handleOpenModal={handleOpenModal} />
    )
  });

  useEffect(() => {
    if (isModalOpen) {
        // Add class to body to prevent scrolling
        document.body.classList.add("overflow-hidden");
    } else {
        // Remove class from body to allow scrolling
        document.body.classList.remove("overflow-hidden");
    }

    // Clean up when component is unmounted or modal is closed
    return () => document.body.classList.remove("overflow-hidden");
  }, [isModalOpen]);

  return (
    <div ref={timelineRef} className="bg-[#7A7A7A] pb-10">
      {
        isModalOpen && (
          <TimelineModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          selectedElement={selectedElement} />
        )
      }
      <h2 className="text-white font-bold text-5xl sm:max-5xl:text-7xl  text-center pt-20 pb-16 align-baseline flex items-center justify-center">
        <i className="fi fi-bs-hourglass-end me-4 mt-2 text-5xl sm:max-5xl:text-6xl"></i> TIMELINE
      </h2>
      <div className="flex flex-row mx-2">
        <button onClick={() => handleScrollSide(false)} className="text-white text-xl md:text-3xl p-3 hidden sm:max-5xl:block">
          <i className="fi fi-sr-angle-left"></i>
        </button>
        <div id="timeline-cards-container" ref={scrollContainer} className="no-scrollbar-content touch-pan-auto gap-5 whitespace-nowrap overflow-auto overflow-x-auto flex snap-x snap-mandatory">
          {displayTimelineCards}
        </div>
        <button onClick={() => handleScrollSide(true)} className="text-white text-xl md:text-3xl p-3 hidden sm:max-5xl:block">
          <i className="fi fi-sr-angle-right"></i>
        </button>
      </div>
    </div>
  )
}

export default TimelineSection