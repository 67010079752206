// Import images
import BGImg from '../assets/imgs/about_us/bg_img.jpg';
import OurStoryImg from '../assets/imgs/about_us/our_story.jpg';

// Import pillars imgs
import Pillar1 from '../assets/imgs/about_us/PRODUCTION.jpg'
import Pillar2 from '../assets/imgs/about_us/INSPIRATIONAL.jpeg'
import Pillar3 from '../assets/imgs/about_us/EXPERIENCES.jpg'



const AboutUs = ({ aboutUsRef, socialMedias }) => {

    const displaySocialMedia = socialMedias.map((socialMedia, index) => {
        return (
          <a key={index} href={socialMedia.link} target={socialMedia.target} rel={socialMedia.rel} className="bg-white w-16 h-16 text-center justify-center items-center justify-items-center flex rounded-full">
            <i className={`fi ${socialMedia.icon} text-[#171717] mt-1 text-3xl p-2`}></i>
          </a>
        )
    });

    const pillars = [
        {title: 'Exceptional Production Quality', src: Pillar1},
        {title: 'Inspiring and Aspirational Community', src: Pillar2},
        {title: 'Unique and Insatiable Experiences', src: Pillar3},
    ]

    const founders = [
        { name: 'Agustin Rivera', role: 'Projects & Operations', src: '' },
        { name: 'Gianmarco Meriggio', role: ' Commercial & Customer Experience', src: '' },
        { name: 'Alessandro Miranda', role: 'Setup, Logistics & Security', src: '' },
        { name: 'Gerardo Nuila', role: 'Business Development , Finances & Legal Matters', src: '' }
    ]

    const displayFounderCards = founders.map((founder, index) => {
        return (
            <div key={index} className="flex flex-col gap-5 text-center w-72 sm:w-64 md:w-72 lg:w-60 xl:w-72 2xl:80 h-96">
                <div className='flex justify-center items-center shadow-lg h-72 rounded-3xl'>
                {founder.src ? (
                    <img className='w-full h-full object-cover' src={founder.src} alt={'founder_' + (index + 1)} />
                ) : (
                    <i className="fi fi-rr-user text-9xl"></i>
                )}
                </div>
                <div className='text-center flex flex-col justify-start items-center h-28 my-3'>
                    <span className="text-xl font-bold">{founder.name}</span>
                    <p className=' font-extralight'>{founder.role}</p>
                </div>
            </div>
        )
    });

    const displayPillars = pillars.map((pillar, index) => {
        return (
            <div className='relative h-96'>
                <img className='absolute w-full h-full object-cover rounded-xl' src={pillar.src} alt={pillar.title} />
                <div className='absolute w-full h-full bg-black opacity-45 rounded-xl'></div>
                <div className="w-full h-full absolute flex text-center justify-center items-center">
                    <h2 className="text-white text-4xl md:text-6xl opacity-95 font-semibold px-5 pb-5">
                    {pillar.title}
                    </h2>
                </div>
            </div>
        )
    });

  return (
    <div ref={aboutUsRef}>
        <div className="max-h-screen h-96 sm:h-[40rem] md:max-xl:h-[50rem] 2xl:h-[55rem] flex relative">
            <img className="h-full w-full object-cover" src={BGImg} alt="f+f_bg_img" />
            <div className="absolute inset-0 bg-gradient-to-br from-[#171717] from-30%"></div>
            <div className="ps-5 sm:max-2xl:pe-20 absolute h-full w-full items-center justify-center flex flex-col text-white text-start gap-4 xl:gap-12">
                <div className="font-bold text-xl sm:text-4xl md:text-5xl lg:max-5xl:text-6xl">
                    We are an electronic music promoter dedicated to creating exceptional musical experiences that connect individuals across cultures.
                </div>
                <p className="text-sm sm:text-xl md:text-2xl lg:max-2xl:text-2xl font-light">
                    Using electronic music as our driving force, we curate unique events in carefully chosen settings that strengthen the bond between music and community. Our aim is to promote emerging talent in the house and techno scene, with the goal of building a global community that comes together to celebrate and create memorable moments.
                </p>
            </div>
        </div>
        <div className="text-2xl my-8">
            <h3 className="mb-2 text-center text-4xl sm:text-4xl md:text-5xl lg:max-2xl:text-6xl font-bold">Three Pillars of Friends + Family:</h3>
            <div className="grid grid-cols-1 xl:max-5xl:grid-cols-3  justify-center items-center gap-3 xl:max-2xl:gap-2 sm:gap-8 lg:max-2xl:gap-2 my-14 mx-2">
                {displayPillars}
            </div>
        </div>
        {/* OUR STORY SECTION */}
        <div className="mt-10 py-10 px-5 grid grid-cols-1 xl:grid-cols-2 bg-[#171717]">
           <div className='text-white mb-5 xl:mb-0 h-2/3'>
                <h2 className="text-5xl sm:text-7xl font-bold">Our Story</h2>
                <br />
                <p className="text-justify text-xl sm:max-5xl:text-2xl">Friends + Family was born from a group of friends who wanted to bring the electronic music experiences and culture they had enjoyed abroad back to their homeland, El Salvador. With a shared vision, they set out to create a community that could grow from their country to the rest of the world, connecting cultures and places. Their dream was to build a space where they could host events in their own way and, above all, a place to celebrate electronic music with Friends + Family.</p>
                <br />
                <p className="text-justify text-xl sm:max-5xl:text-2xl">On July 21, 2023, Friends + Family officially launched with 90 people gathered on a private estate beside the crater of the San Salvador volcano. Since then, it has continued to grow organically, bringing people together through quality music and unforgettable settings, while putting El Salvador on the map of global electronic music culture.</p>
           </div>
           <div className='p-0 xl:max-2xl:p-5 xl:p-10 h-64 sm:max-5xl:h-[40rem]'>
            <img className='rounded-lg h-full w-full object-cover' src={OurStoryImg} alt="our_story" />
           </div>
        </div>
        {/* OUR TEAM SECTION */}
        <div className="text-black mt-10 px-5">
            <h2 className="text-5xl sm:text-7xl font-bold mb-5">Our Team</h2>
            <div className={`justify-items-center grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-${founders.length} gap-2`}>
                {displayFounderCards}
            </div>
            <p className="text-justify text-xl sm:max-2xl:text-2xl mb-5">Together, we work to deliver seamless, unforgettable events, driven by a shared passion for building a global community.</p>
        </div>
        {/* CONTACT SECTION */}
        <div className="text-white mt-10 py-10 px-5 bg-[#171717]">
            <h2 className="text-center text-5xl sm:text-7xl font-bold mb-5">Contact</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 justify-between text-2xl xl:justify-between gap-9 items-center">
                <div className="flex text-center items-center justify-center sm:justify-start gap-2 my-2 sm:my-8">
                    <i className="fi fi-sr-envelope mt-2 text-2xl"></i>
                    <a href='mailto:info@friendsplusfamily.com' target='_blank' rel='noopener noreferrer' className="underline underline-offset-2">info@friendsplusfamily.com</a>
                </div>
                <div id="social-media-container" className="flex justify-center sm:justify-end items-center text-center gap-5 my-2 sm:my-8">
                    {displaySocialMedia}
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs