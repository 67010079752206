import { useState, useRef } from "react";

// Import emailJS
import emailjs from '@emailjs/browser';

const ContactSection = ({ contactRef, socialMedias }) => {
  const service_id = process.env.REACT_APP_SERVICE_ID || "service_rffqk5o";
  const template_id = process.env.REACT_APP_EMPLATE_ID || "template_lg50mka";
  const key = process.env.REACT_APP_PUBLIC_KEY || "aTgRkwIdL3A9IcVm4";

  const form = useRef();
  const [buttonText, setButtonText] = useState('Send Message'); 
  const [isSending, setIsSending] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    setIsSending(true);
    setButtonText('Sending...');

    emailjs.sendForm(
      service_id,
      template_id,
      form.current,
      {publicKey: key}
    )
    .then((result) => {
      console.log(result.text);
      setButtonText("Email sent successfully!");
      setIsSending(false);
    }, (error) => {
      console.error(error.text);
      setButtonText("An error ocurred");
      setIsSending(false);
    }
    );
  };

  const displaySocialMedia = socialMedias.map((socialMedia, index) => {
    return (
      <a
        key={index}
        href={socialMedia.link}
        target={socialMedia.target}
        rel={socialMedia.rel}
        className="bg-[#171717] w-10 h-10 text-center flex items-center justify-center rounded-full"
      >
        <i className={`fi ${socialMedia.icon} text-white mt-1 text-xl`}></i>
      </a>
    );
  });

  return (
    <div
      ref={contactRef}
      className="bg-white p-10 grid grid-cols-1 xl:grid-cols-2 gap-9 items-start xl:items-center"
    >
      {/* Left Side with Description and Social Media */}
      <div className="flex flex-col mt-2"> {/* Added mt-4 to align it with the form */}
        <h2 className="text-[#7A7A7A] font-bold text-sm uppercase mb-2">
          Let's keep in touch!
        </h2>
        <h1 className="text-[#171717] font-bold text-3xl mb-6">
          Let’s stay connected! Reach out to us for inquiries, collaborations, or to learn more about the Friends + Family experience. We’re here to bring your ideas to life and create unforgettable moments together.
        </h1>

        {/* Social Media Icons */}
        <div id="social-media-container" className="flex items-center gap-4 my-8">
          {displaySocialMedia}
        </div>

        {/* Additional Links */}
        <div className="text-sm text-gray-600 space-y-2">
          <p>Privacy statement</p>
          <p>Cookie policy</p>
          <p>Terms & conditions</p>
          <p>Rules</p>
          <p>Press</p>
        </div>
      </div>

      {/* Right Side (Contact Form) */}
      <div className="w-full">
        <form ref={form} onSubmit={onSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold text-gray-700">First Name</label>
            <input
              type="text"
              name="from_first_name"
              placeholder="John"
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-[#7A7A7A]"
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold text-gray-700">Last Name</label>
            <input
              type="text"
              name="from_last_name"
              placeholder="Smith"
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-[#7A7A7A]"
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold text-gray-700">Email*</label>
            <input
              type="from_email"
              name="from_email"
              placeholder="john@example.com"
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-[#7A7A7A]"
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold text-gray-700">Subject</label>
            <input
              type="text"
              name="subject"
              placeholder="How can we help you?"
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-[#7A7A7A]"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold text-gray-700">How can we help you?</label>
            <textarea
              name="message"
              placeholder="Message..."
              rows="4"
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-[#7A7A7A]"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className={`${isSending ? "bg-[#7A7A7A]" : "bg-black"} font-semibold text-white px-4 py-3 rounded-full hover:bg-[#7A7A7A] transition-colors duration-300`}
            disabled={isSending}
          >
            {buttonText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactSection;

