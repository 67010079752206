// Import components
import Carousel from "./Carousel"

// Import react router
import { useNavigate } from 'react-router-dom';

const AboutUsSection = ({ socialMedias }) => {

  const navigate = useNavigate();

  const handleAboutUsRedirect = () => {
    navigate('/about-us'); // Navigate to the /about-us page
    window.scrollTo(0, 0);
  };

  const displaySocialMedia = socialMedias.map((socialMedia, index) => {
    return (
      <a key={index} href={socialMedia.link} target={socialMedia.target} rel={socialMedia.rel} className="bg-[#171717] w-16 h-16 text-center justify-center items-center justify-items-center flex rounded-full">
        <i className={`fi ${socialMedia.icon} text-white mt-1 text-3xl p-2`}></i>
      </a>
    )
  });

  return (
    <div className="bg-white p-5 grid grid-cols-1 xl:grid-cols-3 justify-center xl:justify-between gap-5 items-center">
      <div className="">
        <h2 className="text-[#7A7A7A] font-bold text-xl align-baseline flex items-center justify-start">
            <i className="fi fi-sr-info mt-1.5 me-1 text-xl"></i>  ABOUT US
        </h2>
        <h1 className="text-[#171717] font-bold text-4xl my-6">Friends + Family: One Year of Building a Community Around Electronic Music</h1>
        <p className="text-justify mb-10 text-xl" >In our first year, Friends + Family has evolved from a shared dream among friends into a vibrant movement rooted in El Salvador, Central America.</p>

        <button onClick={handleAboutUsRedirect} className="bg-[#171717] text-white font-bold px-5 py-5 rounded-full hover:bg-[#7A7A7A] text-center justify-center items-center">
          Read more about us <i className="fi fi-ss-angle-right text-xs ms-2"></i>
        </button>

        <div id="social-media-container" className="flex justify-start items-center gap-5 my-8">
          {displaySocialMedia}
        </div>
      </div>
      <div className="w-full xl:col-span-2">
        <Carousel/>
      </div>
    </div>
  )
}

export default AboutUsSection