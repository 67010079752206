

const EventCard = ({ event }) => {

  return (
    <div className="relative overflow-hidden rounded-md transition ease-out hover:scale-105 m-5 w-80 md:w-[23rem] lg:w-[20rem] xl:w-96 2xl:w-96">
        <div className="h-max md:h-96 lg:max-xl:h-72 2xl:h-[28rem] flex z[-1]">
            <img className="w-full h-full object-cover" src={event.flyerSrc} alt={event.alt} />
        </div>
        <div className="h-full w-full items-center justify-start justify-items-center text-black bg-white flex flex-col text-wrap py-1 px-2">
          {
            event.status === "active" ? (
              <a
              href={event.link}
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold py-5 my-3 rounded-full text-xl w-52 text-center hover:bg-[#747474] bg-[#171717] text-white">
                Register
              </a>
            ) : (
              <span className="font-bold py-5 my-3 rounded-full text-xl w-52 text-center bg-[#434343] text-stone-100">
                Coming Soon!
              </span>
            )
          }

        </div>
    </div>
  )
}

export default EventCard