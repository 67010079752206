// Import components
import HeroSection from '../assets/components/HeroSection';
import AboutUsSection from '../assets/components/AboutUs/AboutUsSection';
import EventsSections from '../assets/components/Events/EventsSections';
import TimelineSection from '../assets/components/Timeline/TimelineSection';
import GallerySection from '../assets/components/Gallery/GallerySection';
import ContactSection from '../assets/components/ContactSection';

const Landing = ({ homeRef, programRef, timelineRef, galleryRef, aboutUsRef, contactRef, handleScroll, galleryOption, setGalleryOption, socialMedias }) => {
  return (
    <div ref={homeRef}>
        <HeroSection handleScroll={handleScroll} programRef={programRef} />
        <AboutUsSection socialMedias={socialMedias} />
        <EventsSections programRef={programRef}/>
        <TimelineSection timelineRef={timelineRef} />
        <GallerySection galleryRef={galleryRef} galleryOption={galleryOption} setGalleryOption={setGalleryOption} />
        <ContactSection contactRef={contactRef} socialMedias={socialMedias} />
    </div>
  )
}

export default Landing