import { useState, useEffect, useContext } from "react"
import { Context } from "../../context/Context"

import PlayerModal from './PlayerModal';

// Import iamge covers
import VlogCover1 from '../../imgs/vlogs/Nomading_001_Monkey.jpg';
import VlogCover2 from '../../imgs/vlogs/Nomading_001_Mooglie_Pt1.jpg';
import VlogCover3 from '../../imgs/vlogs/Nomading_001_Mooglie_Pt2.jpg';

import Video2 from '../../media/Nomading 002   Pt  1 w Mooglie.mp4';

const vlogs = [
  {
    videoId: "JL8A3Q8QHXQ",
    name: "Nomading 001 w/ Monkey Safari",
    src: VlogCover1,
    isShort: true,
  },
  {
    videoSrc: Video2,
    videoId: "Q58D0geD_dc",
    name: "Nomading 002 - Pt. 1 w/ Mooglie",
    src: VlogCover2,
    isShort: true,
  },
  {
    videoId: "6IM831z91cA",
    name: "Nomading 001 - Pt. 2 w/ Mooglie",
    src: VlogCover3,
    isShort: true,
  }
];

const VideosGrid = ({ galleryOption }) => {
    const { events } = useContext(Context);
    const videoSources = galleryOption === 'after_movies' ? events : vlogs;
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const openVideo = (index) => {
      setSelectedVideo(galleryOption === 'after_movies' ? events[index] : vlogs[index]);
    };
  
    const closeVideo = () => {
      setSelectedVideo(null);
    };

    useEffect(() => {
      setIsVisible(true); // Animate open
      return () => setIsVisible(false); // Animate close on unmount
    }, []);

  
    return (
      <div>
        {
          galleryOption === "after_movies" ? (
            <div className="columns-1 sm:columns-2 md:columns-2 lg:max-5xl:columns-4 my-10 px-5">
              {/* After Movies */}
              {videoSources.map((event, index) => (
                <div
                  key={"after_movie_" + index}
                  className="cursor-pointer w-full h-96 sm:max-5xl:w-50 flex relative my-8"
                  onClick={() => openVideo(index)}
                >
                  {/* Placeholder Image */}
                  <img
                    src={event.src}
                    className="absolute inset-0 w-full h-full object-cover rounded-xl"
                    alt={event.name}
                  />
        
                  {/* Overlay and Title */}
                  <div className="absolute bg-black opacity-40 w-full h-full rounded-xl"></div>
                  <div className="w-full h-full absolute flex justify-start items-end">
                    <h2 className="text-white text-2xl md:text-3xl font-semibold px-5 pb-5">
                      {event.name}
                    </h2>
                  </div>
        
                  {/* Play Button */}
                  <div className="absolute h-full w-full flex items-center justify-center">
                    <i className="fi fi-sr-play text-4xl md:text-6xl text-white transition ease-out hover:scale-110"></i>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="columns-1 sm:columns-2 md:columns-2 lg:max-5xl:columns-4 my-10 px-5">
              {/* Vlogs */}
              {videoSources.map((event, index) => (
                <div
                  key={"vlog_" + index}
                  className="cursor-pointer w-full h-[35rem] sm:max-5xl:h-[38rem] flex relative my-8"
                  onClick={() => openVideo(index)}
                >
                  {/* Placeholder Image */}
                  <img
                    src={event.src}
                    className="absolute inset-0 w-full h-full object-cover rounded-xl"
                    alt={event.name}
                  />
        
                  {/* Overlay and Title */}
                  <div className="absolute bg-black opacity-40 w-full h-full rounded-xl"></div>
                  <div className="w-full h-full absolute flex justify-start items-end">
                    <h2 className="text-white text-2xl md:text-3xl font-semibold px-5 pb-5">
                      {event.name}
                    </h2>
                  </div>
        
                  {/* Play Button */}
                  <div className="absolute h-full w-full flex items-center justify-center">
                    <i className="fi fi-sr-play text-4xl md:text-6xl text-white transition ease-out hover:scale-110"></i>
                  </div>
                </div>
              ))}
            </div>
          )
        }
    
        <PlayerModal selectedVideo={selectedVideo} closeVideo={closeVideo} isVisible={isVisible} />
      </div>
    );    
  };

export default VideosGrid